.card{
  box-shadow: 0 0 0.875rem 0 rgba(33,37,41,.05);border: 0;
}

.card-header{
  border: 0;background: none;padding-bottom: 0 !important;
  .card-title{
    font-size: 16px;font-weight: 500;margin-bottom: 0;
    @include media-breakpoint-up(lg){
      font-size: 20px;
    }
  }
  @include media-breakpoint-down(md){
    padding: 1rem;
  }
}

.card-body{
  @include media-breakpoint-down(md){
    padding: 1rem;
  }
}