html,
body{
  height: 100%;
  scrollbar-face-color: transparent;
	scrollbar-track-color: transparent;
	scrollbar-arrow-color: #676C72;
	scrollbar-3dlight-color: none;
	scrollbar-shadow-color: #676C72;;
	scrollbar-darkshadow-color: none;
  scrollbar-color: #676C72;
  scrollbar-width: thin;
}
::-webkit-scrollbar{
	width: 4px;
	height: 3px;
	background-color: transparent;
}
::-webkit-scrollbar-track{
	border-radius: 0;
	background-color: transparent;
}
::-webkit-scrollbar-thumb{
	border-radius: 9px;
	background-color: #a5a7ab;;
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

body{
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  &:hover{text-decoration: underline;}
}

h1,h2,h3,h4,h5,h6{margin-bottom: 0;}

button{border: 0;margin: 0;padding: 0;vertical-align: middle;background: transparent;}