[data-picker="date"],
[data-picker="datetime"]{
  background-image: url('../images/icon-calendar.svg'); background-repeat: no-repeat; background-position: right .75rem top 50%;
  display: inline-block;
  & ~ img{vertical-align: middle;margin-left: 5px;cursor: pointer;}
}
[data-picker="date"]{
  width: 140px;
}
[data-picker="datetime"]{
  width: 190px;
}