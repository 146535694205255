.font-12px{font-size: 12px;}
.font-13px{font-size: 13px;}
.font-14px{font-size: 14px;}
.font-15px{font-size: 15px;}
.font-16px{font-size: 16px;}

.mt-10px{margin-top: 10px;}
.mt-20px{margin-top: 20px;}
.mt-30px{margin-top: 30px;}
.mt-40px{margin-top: 30px;}
.mt-50px{margin-top: 50px;}

.mb-10px{margin-bottom: 10px;}
.mb-20px{margin-bottom: 20px;}
.mb-30px{margin-bottom: 30px;}
.mb-40px{margin-bottom: 40px;}
.mb-50px{margin-bottom: 50px;}

.ml-10px{margin-left: 10px;}
.ml-20px{margin-left: 20px;}
.ml-30px{margin-left: 30px;}
.ml-40px{margin-left: 40px;}
.ml-50px{margin-left: 50px;}

.mr-10px{margin-right: 10px;}
.mr-20px{margin-right: 20px;}
.mr-30px{margin-right: 30px;}
.mr-40px{margin-right: 40px;}
.mr-50px{margin-right: 50px;}

.pt-10px{padding-top: 10px;}
.pt-20px{padding-top: 20px;}
.pt-30px{padding-top: 30px;}
.pt-40px{padding-top: 40px;}
.pt-50px{padding-top: 50px;}

.gx-10px{
  margin-left: -5px;margin-right: -5px;
  > *{padding-left: 5px;padding-right: 5px;}
}

.overflow-y-scroll{overflow-y: auto;overflow-x: hidden;max-height: 100%;}