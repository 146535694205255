.page-top{
  margin-bottom: 30px;
  @include media-breakpoint-up(lg){
    display: flex;align-items: flex-end;
    nav{
      margin-left: auto;
    }
  }
  .page-title{
    font-size: 24px;font-weight: 700;margin-bottom: 10px;
    @include media-breakpoint-up(lg){
      font-size: 24px;margin-bottom: 0;
    }
  }

  .breadcrumb{
    font-size: 11px;margin-bottom: 0;
    a{font-size: 11px;}
    @include media-breakpoint-up(lg){
      font-size: 13px;
      a{font-size: 13px;}
    }
  }
}
