@font-face {
  font-family: "Pretendard";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-Thin.woff") format("woff"), url("../fonts/Pretendard/Pretendard-Thin.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-Thin.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-ExtraLight.woff") format("woff"), url("../fonts/Pretendard/Pretendard-ExtraLight.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-ExtraLight.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-Light.woff") format("woff"), url("../fonts/Pretendard/Pretendard-Light.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-Light.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-Regular.woff") format("woff"), url("../fonts/Pretendard/Pretendard-Regular.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-Regular.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-Medium.woff") format("woff"), url("../fonts/Pretendard/Pretendard-Medium.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-Medium.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-SemiBold.woff") format("woff"), url("../fonts/Pretendard/Pretendard-SemiBold.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-Bold.woff") format("woff"), url("../fonts/Pretendard/Pretendard-Bold.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-Bold.otf") format("OpenType");
}
@font-face {
  font-family: "Pretendard";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Pretendard/Pretendard-ExtraBold.woff") format("woff"), url("../fonts/Pretendard/Pretendard-ExtraBold.ttf") format("TrueType"), url("../fonts/Pretendard/Pretendard-ExtraBold.otf") format("OpenType");
}
html,
body {
  height: 100%;
  scrollbar-face-color: transparent;
  scrollbar-track-color: transparent;
  scrollbar-arrow-color: #676C72;
  scrollbar-3dlight-color: none;
  scrollbar-shadow-color: #676C72;
  scrollbar-darkshadow-color: none;
  scrollbar-color: #676C72;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 9px;
  background-color: #a5a7ab;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

body {
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

button {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  background: transparent;
}

.font-12px {
  font-size: 12px;
}

.font-13px {
  font-size: 13px;
}

.font-14px {
  font-size: 14px;
}

.font-15px {
  font-size: 15px;
}

.font-16px {
  font-size: 16px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-20px {
  margin-top: 20px;
}

.mt-30px {
  margin-top: 30px;
}

.mt-40px {
  margin-top: 30px;
}

.mt-50px {
  margin-top: 50px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-30px {
  margin-bottom: 30px;
}

.mb-40px {
  margin-bottom: 40px;
}

.mb-50px {
  margin-bottom: 50px;
}

.ml-10px {
  margin-left: 10px;
}

.ml-20px {
  margin-left: 20px;
}

.ml-30px {
  margin-left: 30px;
}

.ml-40px {
  margin-left: 40px;
}

.ml-50px {
  margin-left: 50px;
}

.mr-10px {
  margin-right: 10px;
}

.mr-20px {
  margin-right: 20px;
}

.mr-30px {
  margin-right: 30px;
}

.mr-40px {
  margin-right: 40px;
}

.mr-50px {
  margin-right: 50px;
}

.pt-10px {
  padding-top: 10px;
}

.pt-20px {
  padding-top: 20px;
}

.pt-30px {
  padding-top: 30px;
}

.pt-40px {
  padding-top: 40px;
}

.pt-50px {
  padding-top: 50px;
}

.gx-10px {
  margin-left: -5px;
  margin-right: -5px;
}
.gx-10px > * {
  padding-left: 5px;
  padding-right: 5px;
}

.overflow-y-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}

[class^=icon-] {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  flex-shrink: 0;
}

.material-icons-outlined {
  vertical-align: middle;
  flex-shrink: 0;
}

.icon-no-img {
  background-image: url("../images/icon-no-img.png");
  width: 30px;
  height: 30px;
}

[data-picker=date],
[data-picker=datetime] {
  background-image: url("../images/icon-calendar.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem top 50%;
  display: inline-block;
}
[data-picker=date] ~ img,
[data-picker=datetime] ~ img {
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

[data-picker=date] {
  width: 140px;
}

[data-picker=datetime] {
  width: 190px;
}

.pagination {
  margin-top: 0.75rem;
}
@media (min-width: 992px) {
  .pagination {
    margin-top: 1.5rem;
  }
}
.pagination .material-icons {
  font-size: 17px;
  vertical-align: middle;
  margin-left: -4px;
  margin-right: -4px;
}

.table th,
.table td {
  white-space: nowrap;
  vertical-align: middle;
}

.table-bordered tbody th {
  background-color: #f8f8f8;
}

@media (max-width: 991.98px) {
  .nav-tabs .nav-link {
    padding: 0.5rem 0.75rem;
    font-size: 13px;
  }
}
.nav-tabs .nav-link:hover {
  text-decoration: none;
}
.nav-tabs .nav-link.active {
  font-weight: 500;
}

.card {
  box-shadow: 0 0 0.875rem 0 rgba(33, 37, 41, 0.05);
  border: 0;
}

.card-header {
  border: 0;
  background: none;
  padding-bottom: 0 !important;
}
.card-header .card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .card-header .card-title {
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .card-header {
    padding: 1rem;
  }
}

@media (max-width: 767.98px) {
  .card-body {
    padding: 1rem;
  }
}

.btn {
  white-space: nowrap;
}

.tempus-dominus-widget {
  padding: 1rem;
}

html,
body,
#wrap,
#main {
  min-height: 100vh;
}

@media (min-width: 992px) {
  #wrap {
    display: flex;
  }
}

#navbar {
  height: 54px;
  background: #fff;
  box-shadow: 2rem 0 2rem 0 rgba(33, 37, 41, 0.1);
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 991.98px) {
  #navbar {
    position: sticky;
    top: 0;
    z-index: 99;
  }
}
@media (min-width: 992px) {
  #navbar {
    height: 80px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

#main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#sidebar {
  width: 240px;
  flex: 0 0 240px;
  background: #292a2a;
  border-right: 1px solid #dee2e6;
  transition: margin-left 0.25s ease-in-out;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
}
html.sidebar-collapsed #sidebar {
  margin-left: -240px;
}
@media (max-width: 991.98px) {
  #sidebar {
    display: none;
  }
}

#container {
  flex-grow: 1;
  position: relative;
  padding: 20px 15px;
}
@media (min-width: 992px) {
  #container {
    padding: 40px;
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
}
.navbar-brand img {
  max-width: 120px;
  max-height: 24px;
}
@media (min-width: 992px) {
  .navbar-brand {
    display: none;
  }
}

.btn-sidebar-toggler {
  border: 0;
  margin: 0;
  padding: 0.5rem;
  background: transparent;
  margin-left: -0.5rem;
  display: none;
}
@media (min-width: 992px) {
  .btn-sidebar-toggler {
    display: block;
  }
}

.gnb {
  display: flex;
  gap: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.gnb a {
  color: #C8C9CC;
  font-size: 16px;
  font-weight: 700;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
}
.gnb a.active {
  color: #292A2A;
}
.gnb a.active::before {
  content: "";
  height: 2px;
  border-radius: 10rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #292A2A;
}
@media (max-width: 991.98px) {
  .gnb {
    display: none;
  }
}

.navbar-user-info {
  font-size: 13px;
  font-weight: 700;
  display: none;
}
.navbar-user-info a {
  color: #292A2A;
}
@media (min-width: 992px) {
  .navbar-user-info {
    font-size: 14px;
    display: block;
  }
}

.btn-logout {
  border: 0;
  padding: 0;
  background: transparent;
}
.btn-logout path {
  transition: 0.15s;
}
.btn-logout:hover path {
  fill: #292A2A;
}
@media (max-width: 991.98px) {
  .btn-logout {
    display: none;
  }
}

.btn-allmenu {
  border: 0;
  padding: 0;
  background: transparent;
}
.btn-allmenu .material-icons-outlined {
  font-size: 28px;
}
@media (min-width: 992px) {
  .btn-allmenu {
    display: none;
  }
}

.sidebar-brand {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: #fff;
  height: 80px;
  flex-shrink: 0;
  margin-bottom: 30px;
}

.icon-sidebar-calendar {
  width: 32px;
  height: 32px;
  background-image: url("../images/icon-sidebar-calendar.svg");
}

.icon-sidebar-building {
  width: 32px;
  height: 32px;
  background-image: url("../images/icon-sidebar-building.svg");
}

.icon-sidebar-gear {
  width: 32px;
  height: 32px;
  background-image: url("../images/icon-sidebar-gear.svg");
}

.icon-sidebar-qrcode {
  width: 32px;
  height: 32px;
  background-image: url("../images/icon-sidebar-qrcode.svg");
}

.sidebar-title {
  display: flex;
  align-items: center;
  padding: 16px 40px;
  margin-bottom: 10px;
  flex-shrink: 0;
}
.sidebar-title .title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  flex-grow: 1;
}

.sidebar-nav {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-nav ul {
  padding: 0;
  list-style: none;
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link {
  color: #fff;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  padding: 16px 40px;
  text-decoration: none;
  position: relative;
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link span {
  flex-grow: 1;
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link[data-bs-toggle]::after {
  content: "expand_more";
  display: block;
  transition: 0.15s;
  font-family: "Material Icons Outlined";
  font-size: 24px;
  display: inline-block;
  line-height: 1;
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link[aria-expanded=true]::after {
  transform: rotate(180deg);
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link.active {
  background: #454545;
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link.active::before {
  content: "";
  width: 4px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.sidebar-nav > ul > .sidebar-item > .sidebar-link.active ~ ul {
  background: #454545;
}
.sidebar-nav > ul > .sidebar-item > ul {
  padding-bottom: 6px;
}
.sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link {
  font-size: 14px;
  color: #aaa;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 40px;
}
.sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link::before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #aaa;
}
.sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link.active {
  color: #fff;
}
.sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link.active::before {
  background: #fff;
}

.allmenu {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  left: 100vw;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: left 0.35s ease;
}
.allmenu-top {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 54px;
  padding-left: 15px;
  padding-right: 15px;
}
.allmenu-top .user-info {
  font-size: 14px;
  color: #666;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;
}
.allmenu-top .btn-logout {
  margin-left: 1rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  text-decoration: underline;
}
.allmenu-top .btn-allmenu-close {
  margin-left: auto;
}
.allmenu-top .btn-allmenu-close .material-icons-outlined {
  font-size: 28px;
}
.allmenu-body {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.allmenu-body .title-box {
  font-size: 18px;
  font-weight: 700;
  padding: 15px 15px;
  color: #fff;
  background: #292A2A;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item > .sidebar-link {
  padding: 10px 15px;
  color: #292A2A;
  font-size: 16px;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item > .sidebar-link.active {
  background: #f5f7fb;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item > .sidebar-link.active::before {
  background-color: #292A2A;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item > .sidebar-link.active ~ ul {
  background: #f5f7fb;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link {
  padding: 8px 15px;
  font-size: 15px;
  color: #888;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link.active {
  font-weight: 500;
  color: #292A2A;
}
.allmenu-body .sidebar-nav > ul > .sidebar-item .sidebar-item .sidebar-link.active::before {
  background: #292A2A;
}

html.allmenu-show body {
  overflow: hidden;
}
html.allmenu-show .allmenu {
  left: 0;
}

.page-top {
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .page-top {
    display: flex;
    align-items: flex-end;
  }
  .page-top nav {
    margin-left: auto;
  }
}
.page-top .page-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .page-top .page-title {
    font-size: 24px;
    margin-bottom: 0;
  }
}
.page-top .breadcrumb {
  font-size: 11px;
  margin-bottom: 0;
}
.page-top .breadcrumb a {
  font-size: 11px;
}
@media (min-width: 992px) {
  .page-top .breadcrumb {
    font-size: 13px;
  }
  .page-top .breadcrumb a {
    font-size: 13px;
  }
}

.dashboard-wrap {
  grid-template-columns: 1fr 1fr;
}
.dashboard-wrap .dashboard-area:last-child {
  grid-column-end: span 2;
}
.dashboard-wrap .card {
  min-height: 180px;
}
.dashboard-wrap .detail-card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.dashboard-wrap .detail-card-list.whide {
  grid-template-columns: repeat(6, 1fr);
}
.dashboard-wrap .fa-minus {
  font-size: 10px;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #000;
  padding: 60px;
}
.login h1 img {
  vertical-align: top;
}
.login .login-form {
  width: 520px;
  background: #fff;
  margin-left: 120px;
  padding: 60px;
}
.login .login-form h2 {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 40px;
}
.login .login-form .form-label {
  font-weight: 900;
}
.login .login-form .form-check-label {
  font-size: 12px;
  font-weight: 700;
}
.login .login-form .btn-lg {
  margin-top: 40px;
}