.navbar-brand{
  display: flex;align-items: center;justify-content: center;height: $navbar-height;
  img{max-width: 120px;max-height: 24px;}
  @include media-breakpoint-up(lg){
    display: none;
  }
}

.btn-sidebar-toggler{
  border: 0;margin: 0;padding: .5rem;background: transparent;margin-left: -.5rem;display: none;
  @include media-breakpoint-up(lg){
    display: block;
  }
}

.gnb{
  display: flex;gap: 40px;list-style: none;margin: 0;padding: 0;
  a{
    color: #C8C9CC;font-size: 16px;font-weight: 700;height: $navbar-height-lg;
    display: flex;align-items: center;justify-content: center;text-decoration: none;position: relative;
    &.active{
      color: $dark;
      &::before{content: '';height: 2px;border-radius: 10rem;position: absolute;bottom: 0;left: 0;right: 0;background: $dark;}
    }
  }
  @include media-breakpoint-down(lg){
    display: none;
  }
}

.navbar-user-info{
  font-size: 13px;font-weight: 700;display: none;
  a{color: $dark;}
  @include media-breakpoint-up(lg){
    font-size: 14px;display: block;
  }
}

.btn-logout{
  border: 0;padding: 0;background: transparent;
  path{transition: .15s;}
  &:hover{
    path{fill: $dark}
  }
  @include media-breakpoint-down(lg){
    display: none;
  }
}

.btn-allmenu{
  border: 0;padding: 0;background: transparent;
  .material-icons-outlined{font-size: 28px;}
  @include media-breakpoint-up(lg){
    display: none;
  }
}