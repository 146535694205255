html,
body,
#wrap,
#main{min-height: 100vh;}

#wrap{
  @include media-breakpoint-up(lg){
    display: flex;
  }
}

#navbar{
  height: $navbar-height;background: #fff;
  box-shadow: 2rem 0 2rem 0 rgba(33,37,41,.1);
  padding-left: $container-padding-x;
  padding-right: $container-padding-x;
  @include media-breakpoint-down(lg){
    position: sticky;top:0;z-index: 99;
  }
  @include media-breakpoint-up(lg){
    height: $navbar-height-lg;
    padding-left: $container-padding-x-lg;
    padding-right: $container-padding-x-lg;
  }
}

#main{
  display: flex;flex-direction: column;flex: 1;
}

#sidebar{
  width: $sidebar-width;flex: 0 0 $sidebar-width;background: #292a2a;border-right: 1px solid $border-color;transition: margin-left .25s ease-in-out;height: 100vh;
  position: sticky;top:0;display: flex;flex-direction: column;
  html.sidebar-collapsed &{
    margin-left: -$sidebar-width;
  }
  @include media-breakpoint-down(lg){
    display: none;
  }
}

#container{
  flex-grow: 1;position: relative;
  padding: 20px $container-padding-x;
  @include media-breakpoint-up(lg){
    padding: $container-padding-x-lg;
  }
}