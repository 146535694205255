.sidebar{

}

.sidebar-brand{
  width: $sidebar-width;display: flex;align-items: center;justify-content: center;padding: 15px 30px;background: #fff;height: $navbar-height-lg;flex-shrink: 0;
  margin-bottom: 30px;
}

.icon-sidebar-calendar{width: 32px;height: 32px;background-image: url('../images/icon-sidebar-calendar.svg');}
.icon-sidebar-building{width: 32px;height: 32px;background-image: url('../images/icon-sidebar-building.svg');}
.icon-sidebar-gear{width: 32px;height: 32px;background-image: url('../images/icon-sidebar-gear.svg');}
.icon-sidebar-qrcode{width: 32px;height: 32px;background-image: url('../images/icon-sidebar-qrcode.svg');}

.sidebar-title{
  display: flex;align-items: center;padding: 16px 40px;margin-bottom: 10px;flex-shrink: 0;
  .title{font-size: 20px;font-weight: 700;color: #fff;flex-grow: 1;}
}

.sidebar-nav{
  flex-grow: 1;overflow-y: auto;overflow-x: hidden;
  ul{padding: 0;list-style: none;}
  > ul{
    > .sidebar-item{
      > .sidebar-link{ //1뎁스
        color: #fff;display: flex;font-weight: 500;font-size: 16px;
        padding: 16px 40px;text-decoration: none;position: relative;
        span{flex-grow: 1;}
        &[data-bs-toggle]{
          &::after{
            content: 'expand_more';display: block;transition: .15s;
            font-family: 'Material Icons Outlined';font-size: 24px;display: inline-block;line-height: 1;
          }
        }
        &[aria-expanded="true"]{
          &::after{transform: rotate(180deg);}
        }
        &.active{
          background: #454545;
          &::before{
            content: '';width: 4px;height: 100%;background-color: #fff;position: absolute;left: 0;top: 0;
          }
          & ~ ul{background: #454545;}
        }
      }
      > ul{padding-bottom: 6px;}
      .sidebar-item .sidebar-link{ //2뎁스
        font-size: 14px;color: #aaa;display: flex;align-items: center;gap: 5px;padding: 10px 40px;
        &::before{content: '';width: 4px;height: 4px;border-radius: 50%;background: #aaa;}
        &.active{color: #fff;
          &::before{background: #fff;}
        }
      }
    }
  }
}



